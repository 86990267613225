<template>
  <div class="flex h-dvh">
    <main class="flex-1">
      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-3 py-9 lg:px-6 lg:py-12">
        <img
          class="mx-auto h-auto w-14 lg:w-40"
          src="@/assets/images/logo.svg"
          alt="ER-BOX by Elektroraad"
        >
      </div>
      <slot />
    </main>
  </div>
</template>
